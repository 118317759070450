.sp-break {
	display: inline-block;
}


// home
.top_access {
	p {
		text-align: center;
	}
}


// FOOTER


// 採用情報
.recruit {
  .recruit_top_img {
    background: url(../img/page/img_recruit_pc.jpg) no-repeat;
    background-position: top left;
    background-size: cover;
    height: 160px;
  }
}


// CONTACT
.contact {
	p {
		text-align: center;
	}
	.contact_atn {
		text-align: left;
	}
}




