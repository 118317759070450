@charset "UTF-8";
@import "normalize";

@mixin headingStyle01 {
	@include font-size(1.9);
	border-bottom: 2px solid $key-color01;
	color: $key-color01;
	font-family: $accent_font;
	line-height: 1.3;
}

@mixin txtStyle01 {
	@include font-size(1.3);
	line-height: 1.5;
	text-align: justify;
}


// button
@mixin btnStyle01 {
	background: lighten($key-color01, 57%);
	border: 1px solid lighten($key-color01, 30%);
	color: $key-color01;
	display: inline-block;
	margin: 0 .4em;
	position: relative;
	&:hover {
		background: $key-color01;
		border-color: $key-color01;;
		color: #fff;
	}
}

@mixin btnStyle02 {
	@include font-size(1.3);
	background: #f6f7f8;
	border-top: 1px solid #eee;
  border-right: 1px solid #ddd;
  border-bottom: 3px solid #ccc;
  border-left: 1px solid #ddd;
  border-radius: 5px;
  display: block;
  font-weight: bold;
  letter-spacing: .04em;
  margin: 0 auto;
  padding: .8em 0;
  text-align: center;
  width: 99%;
  &:hover {
  	box-shadow: 0 1px 4px #bbb;
  }
  i {
  	color: #777;
  	padding-right: .2em;
  }
}

.ex-link {
	@include btnStyle02;
}