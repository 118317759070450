.sp-hide { display: block; }
.sp-hide-ib { display: inline-block; }
.pc-hide { display: none; }
.table-cell-pc {
	overflow: hidden;
	display: table;
	width: 100%;
}
.row-wrap {
	padding-top: 10px;
}

.cont,
.cont2 {
  margin: 0 auto;
  padding: 60px 0 100px;
  p {
    text-align: left;
    padding: 0;
    line-height: 1.8;
    margin: 0;
    margin-bottom: 40px;
    font-size: 1.5rem;
  }
}

.cont {
  width: 1100px;
}
.cont2 {
  width: 1000px;
}

p {
    font-size: 1.5rem;
    line-height: 1.8;
}

body {
  border-left:10px solid #FFF;
  border-right:10px solid #FFF;
}



// ローディングアニメーション
#loading svg {
  width: 580px;
  height: 117px;
}

#loading {
  position: relative;
  width: 100%;
  height: 100%;
}

#in_load {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 580px;
  height: 117px;
  margin-top: 340px;
  margin-left: -290px;
}

#loading_container {
  display: none;
}






// ヘッダー
// ロゴ
.head_wrap {
  background: #FFF;
  display: fixed;
  h1 {
    padding: 10px 40px 6px;
    width: 150px;
    float: left;
    a {
      opacity:1;
    }
  }
  .icon_logo_head {
    width: 100px;
    height: 55px;
  }
}

.icon_logo_head {
  width: 101px;
  height: 55px;
}



// グローバルナビ
.head_wrap {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  background: rgba(255,255,255,.9);
}

.menu-toggle {
  display: none;
}

nav {
  .current-item {
    color: #c8051b;
  }
  float: right;
  width: 1100px;
  ul {
    margin: 0;
    text-align: right;
    li {
      color: #000;
      display: inline-block;
      text-align: center;
    }
    .btn_red {
      background: #c8051b;
      color: #FFF;
      border-left: 1px solid #FFF;
      width: 170px;
      box-sizing: border-box;
      a {
        box-sizing: border-box;
        display: block;
        height: 75px;
        line-height: 75px;
        font-size: 1.5rem;
        color: #FFF;
        &:hover {
          background: #333;
          color: #FFF;
          opacity: 1;
        }
      }
    }
    a {
      color: #000;
      font-size: 1.5rem;
      margin-right: 55px;
      &:hover {
        background: #FFF;
        color: #c8051b;
        opacity: 1;
      }
    }
    .nav_freedial {
      margin: 0;
      margin-right: 20px;
      a {
        font-family: 'Noto Sans Japanese', sans-serif;
        font-style: normal;
        border:none;
        padding: 0;
        font-size: 1.7rem;
        line-height: 1;
        width: inherit;
        margin: 0;
      }
      i {
        margin-right: 5px;
        font-size: 1.8rem;
      }
    }
  }
  ul.hidden {
    display: block;
  }
}



// ボタン共通
.button,
.contact_button input {
  a {
    width: 280px;
    margin: 0 auto;
    border: 2px solid #c0021f;
    font-size: 1.5rem;
  }
  a:hover {
    background: #c0021f;
    color: #FFF;
    opacity: 1;
  }
}

.contact_button input:hover {
  background: #c0021f;
  color: #FFF;
  opacity: 1;
}



// タイトル共通
.cmn_title {
  padding-top: 100px;
  margin-bottom: 40px;
  h1 {
    font-size: 3.7rem;
    letter-spacing: inherit;
  }
}




.body_wrap {
  top:75px;
}

.fix_line {
  position: fixed;
  bottom: 0;
  height: 10px;
  width: 100%;
  background: #FFF;
  z-index: 9999;
}



// TOP
// スライダー
#slide {
  .skippr > div {
    background-position: 74% 62.5%;
    height: 99%;
  }
  .slider_cap {
    top: 200px;
    left: 250px;
    h1 {
      img {
        width: 100%;
      }
    }
  }
}




.top_business {
  overflow: hidden;
  .top_business_hero {
    height: 340px;
    padding-top: 70px;
    h1 {
      font-size: 3.3rem;
      line-height: 1.5;
    }
  }
  .top_business_box {
    padding: 65px 0 0;
    margin: -160px auto;
    width: 530px;
    display: inline-block;
    h2 {
      font-size: 3.2rem;
      margin-bottom: 20px;
      span {
        font-size: 1.6rem;
        padding-top: 3px;
      }
    }
    img {
      padding: 0 25px;
    }
    p {
      padding: 20px 25px 30px;
      margin-bottom: 0;
    }
  }
  .top_business_box:first-child {
    margin-right: 35px;
  }
  .top_business_icon:before {
    width: 85px;
    height: 85px;
    top: -45px;
  }
  .top_business_icon.ic_red:before {
    border: 3px solid #FFF;
  }
  .top_business_icon.ic_white:before {
    border: 3px solid #c0021f;
  }
  .icon_business_hair,
  .icon_business_cosme {
    width: 50px;
    height: 50px;
    top: -23px;
  }
  .btn_top_business {
    a {
      font-size: 1.8rem;
      height: 55px;
      line-height: 55px;
    }
    a:after {
      font-size: 2.4rem;
      right: 40px;
    }
    a:hover {
      background: #333;
      opacity: 1;
    }
  }
}



.top_about {
  .top_about_hero {
    width: 100%;
    height: 290px;
    background: url(../img/home/bk_top_business.jpg) no-repeat;
    background-size: cover;
    background-position: top center;
  }
  .cmn_title {
    margin-bottom: 15px;
    padding-top: 120px;
  }
  h2 {
    font-size: 2.1rem;
    padding-top: 15px;
    letter-spacing: .1rem;
  }
  p {
    margin-bottom: 35px;
    text-align: center;
    line-height: 2.1;
    letter-spacing: .1rem;
  }
}

.lohas_txt_wrap {
  width: 1100px;
  margin-bottom: 20px;
}

.effect svg {
  width: 580px;
  height: 117px;
}



// TOPボタンエリア
.top_buttn_wrap {
  background: #f8f8f8;
  .top_buttn_area {
    background: inherit;
    width: 1100px;
    margin: 0 auto;
    padding: 40px 0;
    overflow: hidden;
    .top_button_box {
      background: #c0021f;
      width: 350px;
      height: 175px;
      box-sizing: border-box;
      padding: 9px 17px 8px;
      color: #FFF;
      display: inline-block;
      margin-right: 20px;
      h1 {
        font-size: 3.2rem;
        font-weight: bold;
        font-family: 'Poppins', 'Noto Sans Japanese', sans-serif;
        line-height: 1;
        display: inline-block;
        margin-right: 15px;
      }
      p {
        margin-bottom: 20px;
        font-size: 1.4rem;
      }
      .icon_logo_top_button {
        fill: #FFF;
        width: 50px;
        height: 50px;
      }
    }
    .top_button_box:last-child {
      margin-right: 0;
    }
    .button {
      a {
        width: 100%;
        text-align: center;
        display: block;
      }
      a:hover {
        background: #333;
      }
    }
  }
}




// FOOTER
footer {
  // border-left:10px solid #FFF;
  // border-right:10px solid #FFF;
  border-bottom:10px solid #FFF;
  .footer {
    font-size: 1.4rem;
    line-height: 1.8;
    height: 440px;
    padding: 65px 0 10px;
    box-sizing: border-box;
  }
  .icon_logo_head {
    width: 117px;
    height: 64px;
    margin-bottom: 18px;
  }
  .footer-copy {
    padding: 0;
    .sticky-label {
      opacity: 0;
    }
    .visible {
      position: fixed;
      color: #222;
      opacity: 1;
      font-size: 1.2rem;
      text-align: center;
    }
    .site-label.fixed-right {
      right: -90px;
      transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
    }
    .site-label {
      text-transform: uppercase;
      padding: 30px;
      color: #222;
      margin: 0;
      z-index: 999;
      position: fixed;
      top: 50vh;
      width: 300px;
      height: 40px;
      transition: opacity 100ms ease-out;
      font-size: 1.1rem;
    }
  }
  .foot_address {
    margin-bottom: 6px;
  }
  .foot_tel {
    margin-bottom: 40px;
    line-height: 1;
    li {
      font-size: 2.8rem;
      span {
        font-size: 2.3rem;
        margin-right: 10px;
      }
    }
  }
  .footer-menu_list {
    margin-bottom: 25px;
    li {
      display: inline-block;
      margin-right: 80px;
      i {
        margin-right: 8px;
      }
    }
    li:last-child {
      margin-right: 0;
    }
  }
  .foot_business {
    margin-bottom: 35px;
    li {
      a {
        font-size: 1.8rem;
        width: 325px;
        height: 60px;
      }
      a:hover {
        background: #FFF;
        color: #000;
        opacity:1;
      }
    }
    li:first-child {
      margin-right: 45px;
    }
  }
  .foot_privacy {
    font-size: 1.4rem;
    width: 1200px;
    margin: 0 auto;
    a {
      font-size: 1.3rem;
    }
  }
}




// 会社概要
.about {
  .about_cont {
    position: relative;
  }
  .about_wrap {
    width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
  .about_outline {
    min-height: 650px;
    padding-top: 1px;
    position: relative;
    background: none;
    margin-bottom: 40px;
    .about_text_area {
      background: #fff;
      padding: 60px 50px;
      width: 575px;
      min-height: 580px;
      position: relative;
      z-index: 2;
      margin-top: 60px;
      margin-left: auto;
      h2 {
        font-size: 3.3rem;
        line-height: 1.6;
        margin-bottom: 25px;
      }
      p {
        font-size: 1.5rem;
        line-height: 2.1;
      }
    }
    .about_text_area::before,
    .about_text_area::after {
      position: absolute;
      content: '';
      width: 210px;
      height: 130px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .about_text_area::before {
      top: -3px;
      left: -3px;
      border-top: 3px solid #c0021f;
      border-left: 3px solid #c0021f;
    }
    .about_text_area::after {
      bottom: -3px;
      right: -3px;
      border-bottom: 3px solid #c0021f;
      border-right: 3px solid #c0021f;
    }
  }
  .about_img_area {
    position: absolute;
    top: 0;
    right: 30%;
    z-index: -1;
    img {
      max-width: inherit;
    }
  }
  .about_outline2 {
    width: 1000px;
    margin: 0 auto 120px;
    .cmn_title {
      margin-bottom: 80px;
    }
    dl {
      margin-bottom: 25px;
      padding-bottom: 25px;
      font-size: 1.5rem;
    }
    dl:last-child {
      border-bottom: 1px solid #CCC;
    }
    dt {
      font-size: 1.5rem;
      letter-spacing: .1rem;
      color: inherit;
    }
    dd {
      padding-left: 180px;
      font-size: 1.5rem;
    }
  }
  .bk_about_gray {
    height: 600px;
    width: 100%;
    position: absolute;
    top: 120px;
    left: 0;
    z-index: -2;
    background-color: #f8f8f8;
  }
}





// アクセス
// google map responsive
.g-map {
  padding: 0 0 50%; // %数値で縦横比コントロール
}

.access {
  p {
    text-align: center;
  }
  .access_map {
    margin: 40px 0 50px;
  }
}



// コスメティクス
.cosme {
  .cmn_title h1 {
    font-size: 3.1rem;
  }
  .bk_gray {
    padding: 60px 0 80px;
  }
  .cosme_lineup_area {
    h2 {
      font-size: 2.6rem;
      margin-bottom: 20px;
    }
    .cosme_lineup_btn {
      padding: 20px 0 8px;
      margin-bottom: 0;
      li {
        width: 270px;
        margin: 0 0 12px 3px;
        small {
          font-size: 1.4rem;
          margin-bottom: 5px;
        }
        a {
          font-size: 2.1rem;
          padding: 18px 0 13px;
        }
        a:hover {
          color: #c0021f;
          opacity: 1;
        }
      }
    }
  }
  .cosme_series_tit {
    margin-bottom: 27px;
    ul {
      li {
        width: 135px;
        height: 135px;
        padding: 32px 0;
        font-size: 2rem;
        line-height: 1.3;
        span {
          font-size: 1.2rem;
          padding-top: 7px;
        }
      }
    }
    h3 {
      font-size: 3.4rem;
      padding-left: 0;
      line-height: 1.2;
      small {
        font-size: 1.5rem;
        padding-top: 3px;
      }
    }
  }
  .cosme_box {
    padding: 80px 105px 80px;
    width: 1100px;
    margin: 0 auto 55px;
    p {
      font-size: 1.4rem;
      line-height: 2;
    }
    img {
      margin-bottom: 40px;
      width: 890px;
    }
  }
  .cosme_box:nth-child(odd) {
    background: #FFF;
  }
  .cosme_box > span {
    top: -155px;
  }
}



// 採用情報
.recruit {
  .recruit_top_img {
    height: 250px;
  }
  .cont2 {
    padding: 40px 0 60px;
  }
  .recruit_cont {
    padding: 50px 0 90px;
    background: url(../img/common/bg_grid_60.gif);
  }
  .salut_txt {
    margin-bottom: 55px;
    font-size: 1.6rem;
  }
  .recruit_list {
    overflow: hidden;
  }
  .recruit_list > li {
    padding: 40px 40px 50px;
    margin: 0;
    width: 47.5%;
    height: 1080px;
    float: left;
  }
  .recruit_list > li:first-child {
    margin-right: 42px;
  }
  .recruit_list_title {
    font-size: 2.1rem;
    margin-bottom: 40px;
  }
  .recruit_list_img {
    margin-bottom: 22px;
    padding-top: 33px;
    height: 13rem;
    width: 13rem;
    .icon_recruit_hair,
    .icon_recruit_staff {
      width: 65px;
      height: 65px;
    }
  }
  .recruit_detail {
    font-size: 1.5rem;
    dt {
      margin-bottom: 13px;
      padding-bottom: 3px;
      font-size: 1.7rem;
    }
    dd {
      margin-bottom: 23px;
    }
    span {
      font-size: 1.4rem;
      line-height: 1.4;
    }
    li {
      margin-bottom: 5px;
    }
  }
}

.recruit_method {
  background: rgba(193,2,31,.8);
  padding: 30px 5%;
  dt {
    font-size: 1.8rem;
    margin-bottom: 13px;
  }
  dd {
    font-size: 3.5rem;
    line-height: 1;
    letter-spacing: .1rem;
    span {
      font-size: 1.8rem;
      margin-right: 10px;
    }
  }
}



// コンタクト
.custom01 {
  p {
    margin: 25px auto;
  }
  input,
  textarea {
    width: 600px;
  }
  textarea {
    height: 150px;
  }
  label {
    left: 1rem;
  }
}

.contact {
    p {
      margin-bottom: 0;
      text-align: center;
    }
  .contact_atn {
    width: 600px;
    margin: 0 auto 40px;
    text-align: left;
    font-size: 1.3rem;
  }
}

.screen-reader-response {
  text-align: center;
}



// プライバシーポリシー
.privacy {
  font-size: 1.5rem;
  p {
    margin-bottom: 60px;
  }
  .privacy_cont {
    margin-bottom: 50px;
    dt {
    font-size: 1.9rem;
    letter-spacing: .3rem;
    margin-bottom: 3px;
    }
    dd {
      margin-bottom: 30px;
    }
  }
  .privacy_name {
    margin-bottom: 30px;
  }
}































