@charset "UTF-8";

@import url(http://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

@import url('https://fonts.googleapis.com/css?family=Poppins:400,600');

body {
  font-family: 'Poppins','Noto Sans Japanese', sans-serif;
  font-size: 100%;
}


// ページ読み込み時にフェードイン
body {
  display: none;
}

img {
	height: auto;
	max-width: 100%;
}
.break,
.sp-break { // 改行
	display: block;
}
.microdata,
.sp-hide,
.sp-hide-ib {
	display: none;
}
.table-cell {
	display: table;
	width: 100%;
}

.C {
  overflow: hidden;
}



// facebook responsive
#fb-root {
	text-align: center;
	width: 100% !important;
}

.row {
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
}

.cont,
.cont2 {
  p {
    text-align: left;
    line-height: 1.7;
    font-size: 1.4rem;
  }
}

.cont {
  padding: 27px 5%;
}

.cont2 {
  padding: 27px 5%;
}



// 背景共通
.bk_gray {
  background: #f8f8f8;
}

.bk_grid {
  background: url(../img/common/bg_grid_60.gif);
}



// ボタン共通
.button {
  a {
    font-weight: bold;
    text-align: center;
    display: block;
    width: 290px;
    height: 50px;
    margin: 0 auto;
    line-height: 52px;
    background: #FFF;
    border: 1px solid #c0021f;
    color: #c0021f;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: .1rem;
    position: relative;
  }
  a:after {
    content: "→";
    font-size: 2rem;
    position: absolute;
    right: 15px;
  }
}

// TOPボタンエリア
.top_buttn_area {
  background: rgba(192,2,31,.8);
  padding: 25px 0;
  margin: 30px auto 0;
  .button {
    margin: 0 auto 8px;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      text-align: left;
      display: inline-flex;
    }
    .icon_logo_top_button {
      fill: #c0021f;
      width: 35px;
      height: 35px;
      margin: 5px 15px 0;
    }
  }
}




// ヘッダー
// ロゴ
.head_wrap {
  background: #FFF;
  h1 {
    padding: 8px 8px 3px;
    height: 55px;
    background: #FFF;
    box-sizing: border-box;
  }
  .icon_logo_head {
    width: 73px;
    height: 40px;
    fill: #CA051B;
  }
}



// グローバルナビ
.head_wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: rgba(255,255,255,1);
}

// ハンバーガー
.menu-toggle {
  width: 25px;
  height: 15px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  &.on {
    .one {
      -moz-transform: rotate(45deg) translate(6px, 8px);
      -ms-transform: rotate(45deg) translate(6px, 8px);
      -webkit-transform: rotate(45deg) translate(6px, 8px);
      transform: rotate(45deg) translate(6px, 8px);
    }
    .two {
      opacity: 0;
    }
    .three {
      -moz-transform: rotate(-45deg) translate(3px, -6px);
      -ms-transform: rotate(-45deg) translate(3px, -6px);
      -webkit-transform: rotate(-45deg) translate(3px, -6px);
      transform: rotate(-45deg) translate(3px, -6px);
    }
  }
}

.one,
.two,
.three {
  width: 100%;
  height: 3px;
  background: #000;
  margin: 5px auto;
  backface-visibility: hidden;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 4em auto;
  text-align: center;
  &.hidden {
    display: none;
  }
  li {
    color: #FFF;
  }
  a {
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    text-decoration: none;
    color: #FFF;
    font-size: 1.9em;
    font-weight: bold;
    line-height: 2.4;
    width: 100%;
    display: block;
    &:hover {
      background-color: rgba(0,0,0,0.5);
      opacity: 1;
    }
  }
  .nav_freedial {
    margin: 20px 0;
    a {
      font-family: 'PT Serif', serif;
      font-style: italic;
      border-bottom: 1px solid #FFF;
      border-top: 1px solid #FFF;
      padding: 15px 0;
      font-size: 2.5rem;
      line-height: 1;
      width: 220px;
      margin: 0 auto;
    }
    i {
      margin-right: 10px;
      font-size: 2.2rem;
    }
  }
}

.menu-section {
  &.on {
  z-index: 10;
  width: 100%;
  height: 100vh;
  display: block;
  background-color: rgba(200,5,27,.8);
  position: absolute;
  }
}



// 共通タイトル
.cmn_title {
  text-align: center;
  letter-spacing: .2rem;
  padding-top: 30px;
  margin-bottom: 23px;
  h1 {
    display: inline-block;
    color: #000;
    font-family: 'Poppins', 'Noto Sans Japanese', sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    position: relative;
    padding-bottom: 10px;
    line-height: 1;
    letter-spacing: 0;
  }
  h1::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 3px solid #ad2036;
    width : 60px;
    width : -webkit-calc(30% - 20px) ;
    width : calc(35px) ;
    margin: 0 auto;
  }
}


.body_wrap {
  position: relative;
  top:55px;
}




// TOP
// スライダー
#slide {
  width: 100%;
  height: 70%;
  min-height: 500px;
  position: relative;
  .skippr-nav-container {
    display: none;
  }
  .skippr > div {
    background-position: 74% 62.5%;
    height: 99.5%;
  }
  .slider_cap {
    position: absolute;
    top: 80px;
    left: 10%;
    z-index: 50;
    h1 {
      img {
        max-width: inherit;
        width: 80%;
      }
    }
  }
}



.top_business {
  .top_business_hero {
    background: url(../img/home/bk_top_about.jpg) no-repeat;
    background-size: cover;
    background-position: top left;
    height: 170px;
    padding-top: 50px;
    width: 100%;
    h1 {
      color: #000;
      text-align: center;
      font-size: 1.9rem;
      font-weight: bold;
      line-height: 1.4;
      text-shadow: 1px 1px 1px #FFF,
      -1px 1px 1px #FFF,
      1px -1px 1px #FFF,
      -1px -1px 1px #FFF;
    }
  }
  .top_business_box {
    border-top: 1px solid #c0021f;
    border-left: 1px solid #c0021f;
    border-right: 1px solid #c0021f;
    padding: 55px 0 0;
    background: #FFF;
    margin: -60px auto 95px;
    position: relative;
    h2 {
      font-size: 2.6rem;
      color: #c0021f;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 14px;
      span {
        display: block;
        font-size: 1.3rem;
        color: #000;
        font-family: 'Noto Sans Japanese', sans-serif;
      }
    }
    img {
      padding: 0 15px;
    }
    p {
      padding: 15px;
    }
  }
  .top_business_box:last-child {
    margin-bottom: 0;
  }
  .top_business_icon:before {
    position: absolute;
    content: "";
    width: 60px;
    height: 60px;
    border-radius: 100%;
    left: 0;
    right: 0;
    top: -25px;
    margin: auto;
  }
  .top_business_icon.ic_red:before {
    background: #c0021f;
    border: 2px solid #FFF;
  }
  .top_business_icon.ic_white:before {
    background: #FFF;
    border: 2px solid #c0021f;
  }
  .icon_business_hair,
  .icon_business_cosme {
    width: 38px;
    height: 38px;
    position: absolute;
    left: 0;
    right: 0;
    top: -10px;
    margin: auto;
  }
  .icon_business_hair {
    fill: #FFF;
  }
  .icon_business_cosme {
    fill: #c0021f;
  }
  .btn_top_business {
    a {
      color: #FFF;
      font-size: 1.5rem;
      text-align: center;
      background: #c0021f;
      display: block;
      width: 100%;
      height: 45px;
      line-height: 45px;
      position: relative;
    }
    a:after {
      content: "→";
      font-size: 2rem;
      position: absolute;
      right: 20px;
    }
  }
}



.top_about {
  .top_about_hero {
    width: 100%;
    height: 170px;
    background: url(../img/home/bk_top_business.jpg);
    background-repeat: no-repeat;
    background-position: top left;
  }
  .cmn_title {
    margin-bottom: 15px;
  }
  h2 {
    color: #000;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
  }
  p {
    margin-bottom: 20px;
  }
}

.lohas_txt_wrap {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  svg {
    width: 330px;
    height: 70px;
    transition: 1s;
  }
}



// SVGエフェクト
.path2,.path3,.path4,.path5,.path6,.path7,.path8,.path9,.path10,.path12,.path14,.path15,.path16,.path17,.path18,.path20,.path21,.path23,.path24,.path25,.path26,.path27,.path28,.path29,.path30,.path31,.path32,.path33,.path34,.path35 {
  fill: none;
  stroke:#000;
  stroke-width: .1;
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
  animation: DASH 2s ease-in alternate forwards;
  // -webkit-animation:DASH 2s ease-in 0s forwards;
  // -o-animation:DASH 2s ease-in 0s forwards;
  animation:DASH 2s ease-in 0s forwards;
}
@keyframes DASH{
  0%{stroke-dashoffset:30;}
  80% {stroke-dashoffset:15;fill:rgba(0,0,0,0.2);}
  100%{stroke-dashoffset:0;fill:rgba(0,0,0,1);}
}

.path1,.path11,.path13,.path19,.path22 {
  fill: none;
  stroke:#cf0012;
  stroke-width: .1;
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
  animation: DASH 2s ease-in alternate forwards;
  // -webkit-animation:DASH2 2s ease-in 0s forwards;
  // -o-animation:DASH2 2s ease-in 0s forwards;
  animation:DASH2 2s ease-in 0s forwards;
}
@keyframes DASH2 {
  0%{stroke-dashoffset:30;}
  80% {stroke-dashoffset:15;fill:rgba(207,0,18,0.2);}
  100%{stroke-dashoffset:0;fill:rgba(207,0,18,1);}
}



// FOOTER
footer {
  font-family: 'Noto Sans Japanese', sans-serif;
  letter-spacing: .1rem;
  .footer {
    text-align: center;
    color: #FFF;
    font-size: 1.3rem;
    line-height: 1.4;
    width: 100%;
    height: 255px;
    padding: 20px 10px 5px;
    box-sizing: border-box;
    background-image: url(../img/common/bk_footer2.jpg);
    // background-image:linear-gradient( to right, rgba(41,50,60,.8),  rgba(72,85,99,.8) ),
    //   url(../img/common/bk_footer.jpg);
    background-repeat:
      repeat, /* 最前面の背景レイヤーに対応 */
      no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    // background-blend-mode: multiply;
  }
  .icon_logo_head {
    fill: #FFF;
    width: 85px;
    height: 53px;
    margin-bottom: 5px;
  }
  .foot_address {
    margin-bottom: 5px;
    color: #FFF;
  }
  .footer-copy {
    text-align: center;
    padding: 3px;
  }
  a {
    color: #FFF;
  }
  .foot_tel {
    margin-bottom: 20px;
    letter-spacing: .1rem;
    li {
      display: inline-block;
      font-size: 2.1rem;
      span {
        font-size: 1.4rem;
        margin-right: 3px;
      }
    }
  }
  .foot_business {
    margin-bottom: 5px;
    li {
      display: inline-block;
      a {
        color: #FFF;
        font-weight: bold;
        font-size: 1.3rem;
        display: table-cell;
        border: 1px solid #FFF;
        width: 140px;
        height: 40px;
        vertical-align: middle;
      }
    }
    li:first-child {
      margin-right: 10px;
    }
  }
  .foot_privacy {
    text-align: right;
    a {
      font-size: 1.1rem;
    }
  }
}



// 会社概要
.about {
  h2 {
    font-size: 2.2rem;
    font-weight: bold;
    color: #000;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  .about_outline {
    background: url(../img/page/bk_about.jpg) no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    padding: 5px 5% 35px;
    margin-bottom: 10px;
    p {
      font-size: 1.5rem;
      line-height: 1.7;
    }
  }
  .about_outline2 {
    dl {
      border-bottom: 1px solid #CCC;
      overflow: hidden;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
    dl:last-child {
      border-bottom:none;
      margin-bottom: 0;
    }
    dt {
      float: left;
      font-size: 1.3rem;
      color: #727171;
      padding-left: 15px;
    }
    dd {
      padding-left: 95px;
      font-size: 1.4rem;
      padding-right: 15px;
    }
  }
}




// ACCESS
// google map responsive
.g-map {
  overflow: hidden;
  height: 0;
  padding: 0 0 56.25%; // %数値で縦横比コントロール
  position: relative;
  iframe,
  object,
  embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
}

.access_map {
  margin: 20px 0;
}

.access_txt {
  margin-bottom: 20px;
}



// コスメティクス
.cosme {
  .cmn_title h1 {
    font-size: 2.1rem;
  }
  .cosme_lineup_area {
    h2 {
      font-size: 1.9rem;
      font-weight: bold;
      color: #000;
      text-align: center;
      margin-bottom: 7px;
    }
    h2:first-letter {
      color: #c0021f;
    }
    .cosme_lineup_btn {
      background: rgba(193,2,31,.8);
      padding: 18px 0 5px;
      text-align: center;
      margin-bottom: 15px;
      li {
        background: #FFF;
        display: inline-block;
        width: 46%;
        margin: 0 0 12px 3px;
        position: relative;
        small {
          font-size: 1.2rem;
          color: #c0021f;
          display: block;
          font-weight: bold;
          margin-bottom: 5px;
        }
        a {
          font-family: 'Poppins', 'Noto Sans Japanese', sans-serif;
          color: #000;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 1;
          display: block;
          padding: 12px 0;
          box-sizing: border-box;
        }
      }
      li:after {
        border-top: 6px solid #fff;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        content: '';
        margin-left: -10px;
        position: absolute;
        bottom: -6px;
        left: 50%;
      }
    }
  }
  .cosme_series_tit {
    margin-bottom: 20px;
    ul {
      position: absolute;
      top: 0;
      left: 0;
      li {
        background: #c0021f;
        width: 70px;
        height: 70px;
        padding: 10px 1px 0;
        text-align: center;
        color: #FFF;
        font-size: 1.3rem;
        font-weight: bold;
        line-height: 1.2;
        span {
          display: block;
          font-size: 8px;
          font-weight: normal;
          padding-top: 3px;
          line-height: 1;
        }
      }
    }
    h3 {
      font-family: 'Poppins', 'Noto Sans Japanese', sans-serif;
      color: #000;
      font-size: 2.3rem;
      font-weight: bold;
      line-height: 1;
      padding-left: 25px;
      small {
        font-size: 1.2rem;
        font-weight: bold;
        display: block;
        padding-top: 3px;
      }
      small:first-letter {
        color: #c0021f;
      }
    }
  }
  .cosme_box {
    position: relative;
    text-align: center;
    background: #FFF;
    padding: 35px 5% 25px;
    p {
      font-size: 1.4rem;
      line-height: 1.7;
      text-align: left;
    }
    img {
      margin-bottom: 20px;
    }
  }
  .cosme_box:nth-child(odd) {
    background: #f8f8f8;
  }
  .cosme_box > span {
    position: relative;
    top: -90px;
    display: block;
  }
}



// 採用情報
.recruit {
  .recruit_top_img {
    background: url(../img/page/img_recruit.jpg) no-repeat;
    background-position: top center;
    background-size: cover;
    height: 125px;
  }
  .recruit_cont {
    padding: 30px 0 45px;
    background: url(../img/common/bg_grid_60.gif);
  }
  .salut_txt {
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
    color: #000;
  }
  .recruit_list > li {
    background-color: #fff;
    padding: 35px 20px 10px;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }
  .recruit_list > .recruit_list1 {
    margin-bottom: 20px;
  }
  .recruit_list > li::before {
    border-radius: 0 0 100%;
    background-color: #000;
    color: #fff;
    font-size: 1.8rem;
    line-height: 2;
    text-indent: -0.5rem;
    height: 4.5rem;
    width: 4.5rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  .recruit_list1::before {
    content: "01";
  }
  .recruit_list2::before {
    content: "02";
  }
  .recruit_list_title {
    font-size: 2rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 2rem;
    line-height: 1.3;
  }
  .recruit_list_img {
    border-radius: 100%;
    display: inline-block;
    margin-bottom: 15px;
    padding-top: 26px;
    height: 10rem;
    width: 10rem;
    position: relative;
    border-radius: 100%;
    background: rgba(247,81,143,1);
    background: linear-gradient(135deg, rgba(247,81,143,1) 30%,rgba(240,112,98,1) 100%);
    .icon_recruit_hair,
    .icon_recruit_staff {
      fill: #FFF;
      width: 50px;
      height: 50px;
    }
  }
  .recruit_detail {
    text-align: left;
    font-size: 1.5rem;
    dt {
      font-weight: bold;
      border-bottom: 1px solid #c0021f;
      margin-bottom: 8px;
      padding-bottom: 4px;
      color: #000;
    }
    dd {
      margin-bottom: 18px;
    }
    span {
      display: block;
      font-size: 1.4rem;
      line-height: 1.3;
    }
    li {
      span {
        padding-left: 1.2rem;
        text-indent: .2rem;
      }
    }
  }
}

.recruit_method {
  background: #c0021f;
  padding: 20px 5%;
  text-align: center;
  font-weight: bold;
  color: #FFF;
  line-height: 1.4;
  dt {
    font-size: 1.6rem;
    margin-bottom: 13px;
  }
  dd {
    font-size: 3rem;
    line-height: 1;
   letter-spacing: .1rem;
    span {
      font-size: 1.5rem;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
}



// コンタクト
.custom01 {
  p {
    position: relative;
    margin-top: 1.5em;
    margin-bottom: 0;
    text-align: left;
  }
  input {
    -webkit-transition: all .2s;
    transition: all .2s;
    font-size: 1.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
  input,
  textarea {
    width: 100%;
    border: 2px solid #FFF;
    background: #FFF;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #333;
    outline: none;
  }
  input {
    padding: .7em;
  }
  textarea {
    height: 150px;
    padding: .7em;
  }
  input:focus,
  textarea:focus {
    box-shadow: 0 0 3px #c0021f;
    border: 2px solid #c0021f;
    background: #FFF;
  }
  .wpcf7-not-valid-tip {
    display: none;
  }
}

.screen-reader-response {
  display: block;
  padding: 15px 0 0;
  color: #c0021f;
  font-size: 1.4rem;
  line-height: 1.4;
  ul {
    display: none;
  }
}

.wpcf7-response-output,
.wpcf7-not-valid-tip {
  display: none;
}

#button_shadow {
  input:focus {
    box-shadow: none;
  }
}

.contact {
  .contact_atn {
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
}

.contact_button {
  input {
    font-weight: bold;
    text-align: center;
    display: block;
    width: 290px;
    height: 50px;
    margin: 0 auto;
    line-height: 45px;
    background: #FFF;
    border: 1px solid #c0021f;
    color: #c0021f;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: .1rem;
    position: relative;
  }
}



// プライバシーポリシー
.privacy {
  font-size: 1.4rem;
  p {
    margin-bottom: 20px;
  }
  .privacy_cont {
    margin-bottom: 40px;
    dt {
      font-size: 1.8rem;
      letter-spacing: .2rem;
      margin-bottom: 5px;
    }
    dt:first-letter {
      color: #c0021f;
    }
    dd {
      margin-bottom: 18px;
    }
  }
  .privacy_name {
    margin-bottom: 30px;
    dt {
      float: left;
    }
    dt:after {
      content: ":";
    }
    dd {
      margin-left: 40px;
    }
  }
  .privacy_sign {
    text-align: right;
  }
}



